import SetMetaData from "../utils/SetMetaData";
// import EPC_img from "../assets/images/epc.jpeg";
import "./Service.css";
import ContactCTA from "../components/ContactCTA/ContactCTA";

const EPC = () => {
  return (
    <>
      <section>
        <div className="u-container">
          <div className="c-service__intro">
            <div>
              <h2>EPC - Energieprestatiecertificaat</h2>
              <h1>Een EPC van een Energiedeskundige Type A?</h1>
              <p>
                Het energieprestatiecertificaat, kortweg EPC, is sinds zijn “geboorte”
                in 2008 belangrijker en belangrijker geworden en heeft ook al heel wat
                nieuwe vormen aangenomen, zowel in methode van opmaak als in soort.
                <br />
                <br />
                We maken hier een onderscheid en bekijken ze.
              </p>
            </div>
            {/* <div className="c-service__intro-visuals">
              <img
                className="c-service__intro-visuals_big"
                src={EPC_img}
                alt="Afbeelding van huis met EPC waardes errond"
              />
            </div> */}
          </div>
        </div>
      </section>
      <section>
        <div className="u-container">
          <div className="c-service__options">
            <div className="c-service__options-card u-is-hot">
              <h2>Residentieel</h2>
              <p>
                Dit is waarschijnlijk het meest voorkomende EPC die aanwezig
                moet zijn bij verhuur of verkoop van een woning. Ook al is een
                EPC 10 jaar geldig, mag een EPC bij verkoop niet ouder zijn dan
                2019.
                <br />
                <br />
                The House Coach beschikt over een erkenning om het EPC
                Residentieel op te maken.
              </p>
            </div>
            <div className="c-service__options-card">
              <h2>Algemene delen</h2>
              <p>
                Dit EPC is verplicht voor alle appartementen in Vlaanderen. Ook
                al zijn er maar twee eenheden in het gebouw, en zijn er weinig
                of geen gemmenschappelijke ruimtes, dan nog is dit EPC een
                verplichting.
                <br />
                <br />
                The House Coach beschikt over een erkenning om dit EPC op te
                maken.
              </p>
            </div>
            <div className="c-service__options-card">
              <h2>Klein niet-residentieel</h2>
              <p>
                Het energieprestatiecertificaat voor kleine niet-residentiële
                eenheden (kortweg EPC kNR) wordt opgemaakt bij de verhuur,
                verkoop en andere overdracht van gebouweenheden die niet bestemd
                zijn om te wonen, zoals een horecazaak, klein kantoor en
                dergelijke.
                <br />
                <br />
                The House Coach beschikt over een erkenning om dit EPC KNR op te
                maken.
              </p>
            </div>
          </div>
        </div>
      </section>
      <ContactCTA />
    </>
  );
};

export default SetMetaData(
    EPC, 
    " - EPC",
    "Leer alles over het Energieprestatiecertificaat (EPC) en hoe TheHouseCoach uw gebouw beoordeelt op energie-efficiëntie. Verkrijg waardevolle inzichten en advies voor verbeteringen.",
    "EPC, energieprestatiecertificaat, energie-efficiëntie, gebouw beoordeling, energiebesparing, TheHouseCoach"
);
