import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Layout from './pages/Layout';
// import AboutUs from './pages/AboutUs';
import EPC from './pages/EPC';
import AsbestAttest from './pages/AsbestAttest';
// import FAQ from './pages/FAQ';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<Home />}/>
        <Route path="/epc" element={<EPC />} />
        <Route path="/asbest-attest" element={<AsbestAttest />} />
        <Route path="/contact" element={<Contact />} />
        {/* <Route path="/over-ons" element={<AboutUs />} /> */}
        {/* <Route path="/faq" element={<FAQ />} /> */}
      </Route>
    </Routes>
  </BrowserRouter>  
  </React.StrictMode>
);
