import SetMetaData from "../utils/SetMetaData";
// import Asbest_IMG from '../assets/images/asbest.jpg';
import ContactCTA from "../components/ContactCTA/ContactCTA";
import './Service.css';
// import Vlaamse_overheid from '../assets/images/vlaamse_overheid.jpg';

const AsbestAttest = () => {
    return(
        <>
        <section>
            <div className="u-container">
                <div className='c-service__intro'>
                    <div>
                        <h2>
                            Asbest attest
                        </h2>
                        <h1>
                            Een officiëel asbest attest voor uw woning?
                        </h1>
                        <p>
                            Als ervaren & gecertificeerde asbestdeskundige bieden wij u onze diensten aan. 
                        </p>
                    </div>
                    {/* <div className='c-service__intro-visuals'>
                        <img className="c-service__intro-visuals_big" src={Asbest_IMG} alt="Afbeelding van Asbest gerelateerde items & waarschuwings tekens" />
                        <img src={Vlaamse_overheid} alt="Vlaamse overheid logo" />
                    </div> */}
                </div>
            </div>
        </section>
        <section>
            <div className='u-container'>
                <div className="c-service__options">
                <div className='c-service__options-card u-is-hot'>
                    <h2>
                        Bij verkoop
                    </h2>
                    <p>
                    Het asbestattest is nodig bij verkoop van woningen gebouwd voor 2001 en voor alle
                    toegankelijke constructies van 20m² of groter met een bouwjaar van voor 2001.
                    Voor toegankelijke constructies gebouwd voor 2001 kleiner dan 20m² is een asbestattest niet
                    verplicht, tenzij:<br/>
                    <ul>
                        <li>
                            De som van de over te dragen toegankelijke constructies 20m² of groter is.
                        </li>
                        <li>
                            De toegankelijke constructie onderdeel uitmaakt van een groter bouwkundig geheel.
                        </li>
                    </ul>
                    De opmaak van een asbestattest voor verkoop moet steeds opgemaakt worden door een
                    asbestdeskundige, ook wel ADI (asbest deskundige inventarisatie) genoemd.
                    <br/><br/>
                    The House Coach is ADI met als Persoons-ID OVAM: AJRZH5XS
                    </p>
                    <hr className="c-card-divider"/>
                    <p>
                        Prijs op aanvraag
                    </p>
                </div>
                <div className='c-service__options-card'>
                    <h2>
                        Bij werken
                    </h2>
                    <p>
                    Dit attest wordt opgemaakt bij renovatiewerken waarbij nog geen asbestattest aanwezig is,
bvb omdat de woning is aangekocht voor de verplichting van een asbestattest bij aankoop
van de woning.<br/><br/>
Dit asbestattest wordt ook wel de destructieve asbestinventaris genoemd.<br/><br/>
Dit is geen verplicht asbestattest maar kan vrijwillig worden aangevraagd of op vraag van de
aannemer die de renovatiewerken zal uitvoeren.
                    </p>
                    <hr className="c-card-divider"/>
                    <p>
                        Prijs op aanvraag
                    </p>
                </div>
                <div className='c-service__options-card'>
                    <h2>
                        Sloopopvolgingsplan
                    </h2>
                    <p>
                    Dit asbestattest is onderdeel van het sloopopvolgingsplan en is verplicht bij:
                    <ul>
                        <li>
                            Infrastructuurwerken groter dan 250m³
                        </li>
                        <li>
                            Niet-residentiële gebouwen met een bouwvolume groter dan 1000m³
                        </li>
                        <li>
                            Residentiële gebouwen met een bouwvolume groter dan 5000m³
                        </li>
                    </ul>
De noodzakelijke erkenning voor de opmaak van een sloopopvolgingsplan is een TRACIMAT
erkenning.<br/><br/>
The House Coach beschikt over een TRACIMAT erkenning.
                    </p>
                    <hr className="c-card-divider"/>
                    <p>
                        Prijs op aanvraag
                    </p>
                </div>
            </div>
            </div>
        </section>   
        <ContactCTA />     
        </>
    );
}

export default SetMetaData(
    AsbestAttest, 
    ' - Asbest',
    "Kom meer te weten over asbestattesten en hoe TheHouseCoach uw gebouw onderzoekt op asbestrisico's. Vertrouw op onze deskundige beoordeling voor veilige en conforme vastgoedtransacties.",
    "asbest, Asbest attest, asbestonderzoek, asbestrisico's, veilige vastgoedtransacties, TheHouseCoach",
);