import './Button.css';

const Button = ({text, link, target=null}) => {
    return(
        <a href={link} target={target==null ? '_self' : target} className="c-btn-primary" >
            {text}
        </a>
    )
}

export default Button;