import './Header.css';
import Logo from '../../assets/images/Logo.png';
import LogoWit from '../../assets/images/Logo-wit.png';
import Button from '../Button/Button';
import { useLayoutEffect, useRef, useState } from 'react';

const Header = ({isHomePage=false}) => {
    const [navOpen, setNavOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [isServiceOpen, setIsServiceOpen] = useState(false);

    const toggleMenu = () => {
        setNavOpen((navOpen) => !navOpen);
    };

    useLayoutEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            const isTop = scrollTop === 0;
            setIsScrolled(!isTop);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const headerRef = useRef(null);

    useLayoutEffect(() => {
        const handleClickOutside = (event) => {
            if (headerRef.current && !headerRef.current.contains(event.target)) {
                setIsServiceOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleServicesToggle = () => {
        setIsServiceOpen(!isServiceOpen);
    }

    return(
        <header className={`${isScrolled ? 'u-header-scrolled' : ''}${isHomePage ? ' u-header-home':''}`}>
            <div className={`c-mob-nav ${navOpen ? 'u-nav-open':''}`}>
                <div className="c-mob-nav__header">
                    <a href="./" >
                        <img className="c-header__logo" src={LogoWit} alt="The House Coach"/>
                    </a>
                    <div onClick={toggleMenu} className="c-mob-nav__header-close">
                        <span className="c-mob-nav__header-close_line"></span>
                        <span className="c-mob-nav__header-close_line"></span>
                    </div>
                </div>
                <nav className="c-mob-nav__nav">
                    <ul>
                        <li>
                            <a href="/">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <line x1="12" y1="5" x2="12" y2="19"></line>
                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                </svg>
                                Home
                            </a>
                        </li>
                        <li>
                            <a href="/EPC">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <line x1="12" y1="5" x2="12" y2="19"></line>
                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                </svg>
                                EPC
                            </a>
                        </li>
                        <li>
                            <a href="/asbest-attest">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <line x1="12" y1="5" x2="12" y2="19"></line>
                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                </svg>
                                Asbest
                            </a>
                        </li>
                        <li>
                            <a href="https://thehousefriend.be" target="_blank" rel="noreferrer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <line x1="12" y1="5" x2="12" y2="19"></line>
                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                </svg>
                                Vastgoed
                            </a>
                        </li>
                        <li>
                            <a href="/contact">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <line x1="12" y1="5" x2="12" y2="19"></line>
                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                </svg>
                                Contacteer ons
                            </a>
                        </li>
                        {/* <li>
                            <a disabled href="/over-ons">Over ons</a>
                        </li> */}
                        {/* <li>
                            <a href="/faq">FAQ</a>
                        </li> */}
                    </ul>
                </nav>
            </div>
            <div className="u-container">
                <div className="c-header">
                    <div>
                        <a href="./" >
                            <img className="c-header__logo" src={isHomePage ? (isScrolled ? Logo : LogoWit) : Logo} alt="The House Coach"/>
                        </a>
                        <nav className='c-header__nav'>
                            <ul>
                                <li className={`c-header__nav-li-dropdown ${isServiceOpen ? 'c-header__nav-li-open' : ''}`}>
                                    <span onClick={handleServicesToggle}>
                                        Diensten
                                       <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                            <polyline points="6 9 12 15 18 9"></polyline>
                                        </svg>
                                    </span>
                                    <ServicesNavItem
                                        toggle={isServiceOpen}
                                    />
                                </li>
                                <li>
                                    <a href="https://thehousefriend.be" target="_blank" rel="noreferrer">
                                        Vastgoed
                                    </a>
                                </li>
                                {/* <li>
                                    <a disabled href="/over-ons">Over ons</a>
                                </li> */}
                                {/* <li>
                                    <a href="/faq">FAQ</a>
                                </li> */}
                            </ul>
                        </nav>
                    </div>
                    <Button 
                        classes='c-header__cta'
                        text='Contacteer ons'
                        link='/contact'
                    />
                    <div className='c-header__hamburger'>
                        <div className={`c-mob-nav-bg ${navOpen ? 'u-nav-open' : ''}`}>
                        </div>
                        <div onClick={toggleMenu} className="c-header__hamburger-group">
                            <span className="c-header__hamburger-patty"></span>
                            <span className="c-header__hamburger-patty"></span>
                            <span className="c-header__hamburger-patty"></span>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

const ServicesNavItem = ({toggle = false}) => {
    return (
        <div className={`c-header__services${toggle ? ' u-services-open' : ''}`}>
            <a href="/EPC" className='c-header__service'>
                <svg className='c-header__service-icon' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24.8 27.333">
                    <g id="house-icon" data-name="house-icon" transform="translate(-3.5 -2)">
                        <path id="Path_5" data-name="Path 5" d="M13.5,27.5V18h5.7v9.5" transform="translate(-0.45 0.833)" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                        <path id="Path_4" data-name="Path 4" d="M4.5,11.867,15.9,3l11.4,8.867V25.8a2.533,2.533,0,0,1-2.533,2.533H7.033A2.533,2.533,0,0,1,4.5,25.8Z" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                    </g>
                </svg>
                <div>
                    <p className='c-header__service-title'>EPC</p>
                    <p className='c-header__service-subtitle'>Energie prestatie certificaat</p>
                </div>
                <svg className="c-header__service-arrow" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <polyline points="9 6 15 12 9 18"></polyline>
                </svg>
            </a>
            <a href="/asbest-attest" className='c-header__service'>
                <svg className='c-header__service-icon' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32.465 28.325">
                    <g id="Group_43" data-name="Group 43" transform="translate(-1.29 -12.778)">
                        <path id="Path_7" data-name="Path 7" d="M18,13.5v8.83" transform="translate(-0.478 7.409)" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                        <path id="Path_8" data-name="Path 8" d="M18,25.5h0" transform="translate(-0.478 9.575)" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                        <path id="Path_6" data-name="Path 6" d="M15.035,5.746,2.718,26.309A2.908,2.908,0,0,0,5.2,30.671H29.839a2.908,2.908,0,0,0,2.487-4.363L20.008,5.746a2.908,2.908,0,0,0-4.973,0Z" transform="translate(0 9.432)" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                    </g>
                </svg>

                <div>
                    <p className='c-header__service-title'>Asbest</p>
                    <p className='c-header__service-subtitle'>Een asbest attest voor uw woning?</p>
                </div>
                <svg className="c-header__service-arrow" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <polyline points="9 6 15 12 9 18"></polyline>
                </svg>
            </a>
        </div>
    );
}

export default Header;