import Logo from '../../assets/images/Logo.png';

import './Footer.css';

const Footer = () =>{
    return(
        <footer>
            <div className="u-container">
                <div className="c-footer__grid">
                    <div>
                        <img src={Logo} alt='Logo van TheHouseCoach' className='c-footer-logo'/>
                        {/* <ul className="c-footer__socials">
                            <li className="c-footer__socials-item">
                                <a href="https://www.facebook.com/TheHouseCoach" target="_blank" rel="noreferrer">
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="32" height="32" viewBox="0 0 64 64">
                                        <path d="M48,7H16c-4.418,0-8,3.582-8,8v32c0,4.418,3.582,8,8,8h17V38h-6v-7h6v-5c0-7,4-11,10-11c3.133,0,5,1,5,1v6h-4 c-2.86,0-4,2.093-4,4v5h7l-1,7h-6v17h8c4.418,0,8-3.582,8-8V15C56,10.582,52.418,7,48,7z"></path>
                                    </svg>
                                </a>
                            </li>
                            <li className="c-footer__socials-item">
                                <a href="https://www.instagram.com/thehousecoach/" target="_blank" rel="noreferrer">
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="32" height="32" viewBox="0 0 64 64">
                                        <path d="M 21.580078 7 C 13.541078 7 7 13.544938 7 21.585938 L 7 42.417969 C 7 50.457969 13.544938 57 21.585938 57 L 42.417969 57 C 50.457969 57 57 50.455062 57 42.414062 L 57 21.580078 C 57 13.541078 50.455062 7 42.414062 7 L 21.580078 7 z M 47 15 C 48.104 15 49 15.896 49 17 C 49 18.104 48.104 19 47 19 C 45.896 19 45 18.104 45 17 C 45 15.896 45.896 15 47 15 z M 32 19 C 39.17 19 45 24.83 45 32 C 45 39.17 39.169 45 32 45 C 24.83 45 19 39.169 19 32 C 19 24.831 24.83 19 32 19 z M 32 23 C 27.029 23 23 27.029 23 32 C 23 36.971 27.029 41 32 41 C 36.971 41 41 36.971 41 32 C 41 27.029 36.971 23 32 23 z"></path>
                                    </svg>
                                </a>
                            </li>
                            <li className="c-footer__socials-item">
                                <a href="https://www.linkedin.com/company/the-house-coach/" target="_blank" rel="noreferrer">
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="32" height="32" viewBox="0 0 64 64">
                                        <path d="M48,8H16c-4.418,0-8,3.582-8,8v32c0,4.418,3.582,8,8,8h32c4.418,0,8-3.582,8-8V16C56,11.582,52.418,8,48,8z M24,47h-5V27h5 V47z M24.029,23.009C23.382,23.669,22.538,24,21.5,24c-1.026,0-1.865-0.341-2.519-1.023S18,21.458,18,20.468 c0-1.02,0.327-1.852,0.981-2.498C19.635,17.323,20.474,17,21.5,17c1.038,0,1.882,0.323,2.529,0.969 C24.676,18.615,25,19.448,25,20.468C25,21.502,24.676,22.349,24.029,23.009z M47,47h-5V35.887C42,32.788,40.214,31,38,31 c-1.067,0-2.274,0.648-2.965,1.469S34,34.331,34,35.594V47h-5V27h5v3.164h0.078c1.472-2.435,3.613-3.644,6.426-3.652 C44.5,26.5,47,29.5,47,34.754V47z"></path>
                                    </svg>
                                </a>
                            </li>
                        </ul> */}
                        <p>
                            © 2024 All Rights Reserved - The House Coach
                        </p>
                        <a href="https://lorenzoverheecke.be" target="_blank" rel="noreferrer">
                            Website door Lorenzo Verheecke
                        </a>
                    </div>
                    <div>
                        <h4 className="c-footer__title">
                            Onze diensten
                        </h4>
                        <ul className="c-footer__links">
                            <li>
                                <a href="./epc">
                                    EPC
                                </a>
                            </li>
                            <li>
                                <a href="./asbest-attest">
                                    Asbest Attest
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h4 className="c-footer__title">
                            Sitemap
                        </h4>
                        <ul className="c-footer__links">
                            <li>
                                <a href="./">
                                    Home
                                </a>
                            </li>
                            <li>
                                <a href="./faq">
                                    FAQ
                                </a>
                            </li>
                            <li>
                                <a href="./contact">
                                    Contact
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h4 className="c-footer__title">
                            Gegevens
                        </h4>
                        <p>
                            Luc Haeck <br/>Energiedeskundige Type A<br/>Asbestdeskundige<br/><br/>EP 09889<br/>AJRZH5XS
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;