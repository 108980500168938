import { Outlet, useLocation } from "react-router-dom";
import Footer from "../components/Footer/Footer"
import Header from "../components/Header/Header"

const Layout = () => {
    const location = useLocation();
    const isHomePage = location.pathname === '/';

    return(
        <>
        <Header isHomePage={isHomePage} />
            <Outlet />
        <Footer />
        </>
    )
}

export default Layout;