import InputField from "../components/Inputs/InputField";
import SetMetaData from "../utils/SetMetaData";
import LucAvatar from '../assets/images/small-avatar-luc.png';

import './Contact.css';
import { useState } from "react";

const Contact = () => {
    const [responseMessage, setResponseMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);

        try {
            const response = await fetch('https://thehousecoach.be/send_mail.php', {
                method: 'POST',
                body: formData
            });

            const data = await response.json();
            setResponseMessage(data.message);

            if (response.ok) {
                form.reset();
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return(
        <section className="u-contact-bg">
            <div className="u-container c-contact">
                <h1>
                    Contacteer ons
                </h1>
                <div className="c-contact__content">
                    <div className="c-contact__persona">
                        <img className="c-contact__persona-img" src={LucAvatar} alt="Foto van Luc Haeck"/>
                        <div className="c-contact__persona-text">
                            <h3>
                                Luc Haeck    
                            </h3>
                            <p>
                                Bemiddelaar, syndicus, Energiedeskundige Type A, asbestdeskundige
                            </p>
                        </div>
                    </div>
                    <div className="c-contact-details">
                        <div className="c-contact-detail">
                            <a href="tel:+32475732740"> 
                                <div className='c-contact-cta__icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                        <path id="Icon_awesome-phone-alt" data-name="Icon awesome-phone-alt" d="M15.543,11.306l-3.5-1.5a.75.75,0,0,0-.875.216l-1.55,1.894A11.583,11.583,0,0,1,4.081,6.378l1.894-1.55a.748.748,0,0,0,.216-.875l-1.5-3.5A.755.755,0,0,0,3.831.019L.581.769A.75.75,0,0,0,0,1.5,14.5,14.5,0,0,0,14.5,16a.75.75,0,0,0,.731-.581l.75-3.25a.759.759,0,0,0-.438-.862Z" fill="#000000"/>
                                    </svg>
                                </div>
                                +32 475 73 27 40
                            </a>
                        </div>
                        <div className="c-contact-detail">
                            <a href="mailto:luc@thehousecoach.be"> 
                                <div className='c-contact-cta__icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 16">
                                        <path id="Icon_material-email" data-name="Icon material-email" d="M19.333,2.667h-16a2,2,0,0,0-1.99,2l-.01,12a2.006,2.006,0,0,0,2,2h16a2.006,2.006,0,0,0,2-2v-12a2.006,2.006,0,0,0-2-2Zm0,4-8,5-8-5v-2l8,5,8-5v2Z" transform="translate(-1.333 -2.667)" fill="#000000"/>
                                    </svg>
                                </div>
                                luc@thehousecoach.be
                            </a>
                        </div>
                    </div>
                    <form className="c-contact__form" onSubmit={handleSubmit}>
                        <InputField 
                        name={"email"}
                        labelText={"E-mail"}
                        type={"email"}
                        classes={"u-grid-span-2-3"}
                        />
                        <InputField 
                        name={"first_name"}
                        labelText={"Voornaam"}
                        type={"text"}
                        classes={"u-grid-span-1-3"}
                        />
                        <InputField 
                        name={"message"}
                        labelText={"Bericht"}
                        isTextArea={true}
                        classes={"u-grid-span-3-3"}
                        />
                        <button type='submit' className='c-contact-btn'>
                            Verzenden
                        </button>
                        {responseMessage && <p className="c-contact__success">{responseMessage}</p>}
                    </form>
                </div>
            </div>
        </section>
    );
}

export default SetMetaData(
    Contact, 
    " - Contact",
    "Neem contact op met TheHouseCoach voor professioneel advies over vastgoedanalyses en -diensten. Wij staan klaar om uw vragen te beantwoorden en u te helpen bij uw vastgoedbehoeften.",
    "Contact, contactgegevens, vastgoedadvies, TheHouseCoach, consultatie"
);
