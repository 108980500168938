import './InputField.css';

const InputField = ({name, labelText, type=null, isTextArea=false, classes=""}) => {
    return(
        <div className={'c-fieldset ' + classes}>
            <label htmlFor={name}>
                {labelText}
            </label>
            {isTextArea===false ? 
                <input required type={type} name={name} id={name} inputMode={type}/>
                : <textarea required rows="10" cols="80" name={name} id={name} inputMode={type} />
            }
        </div>
    );
}

export default InputField;
