import AsbestosImage from '../assets/images/asbestos.jpg';
import EPCImage from '../assets/images/epc2.jpg';
import ConstructImage from '../assets/images/construct.jpg';

import './Home.css';
import ContactCTA from '../components/ContactCTA/ContactCTA';

const Home = () => {
    return(
        <>
        <section className="c-hero">
            <div className="u-container">
                <div className="c-hero-grid">
                    <div>
                        <h1>The House Coach</h1>
                        <p>Úw woning coach. Door middel van onze ervaring treden wij graag op als vastgoeddeskundige bij het verkoop, verhuur of onderhoud van uw vastgoed.</p>
                        <div>

                        </div>
                        <a href="#discover" className="c-hero-cta">
                            <div>
                                <span>
                                    Ontdek meer
                                </span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" role="img" aria-labelledby="discover-arrow" stroke="#000000" strokeWidth="1" strokeLinecap="square" strokeLinejoin="miter" fill="none" color="#000000"> <title id="discover-arrow">Ontdek meer</title> <path d="M19 13V5h-8"/> <path strokeLinecap="round" d="M19 5l-1 1"/> <path d="M18 6L5 19"/> </svg>
                            </div>
                        </a>
                    </div>
                    {/* <div className="c-hero__visual">
                        <img src={LucHeroImage} alt="Luc Haeck"/>
                    </div> */}
                </div>
            </div>
            <svg width="0" height="0" viewBox="0 0 100 100">
                <defs>
                    <clipPath id="clipPath" clipPathUnits="objectBoundingBox">
                        <path d="M1,0.024 C1,0.011,0.99,0,0.976,0 C0.82,0,0.158,0,0.024,0 C0.011,0,0,0.011,0,0.024 V0.89 C0,0.892,0,0.895,0.001,0.897 V0.965 C0.001,0.984,0.017,1,0.036,1 H0.564 C0.576,1,0.587,0.994,0.594,0.983 L0.624,0.933 C0.627,0.928,0.632,0.925,0.638,0.925 H0.965 C0.984,0.925,1,0.909,1,0.89 V0.024"></path>
                    </clipPath>
                </defs>
            </svg>
        </section>
            {/* <section className="c-hero">
                <div className="u-container row">
                    <div className="col">
                        <h1 className="c-hero__title">
                            The House Coach betekent voor u<br/>
                        </h1>
                        <p className="c-hero__sub">
                            Onze bedoeling is om uw onroerend goed in “conditie” te brengen en te houden voor verkoop, verhuur en beheer.
                        </p>
                    </div>
                    <div className="col">
                        <div className="c-hero__visual">
                            <div className="c-hero__visual-blob c-hero__visual-blob-1">
                            </div>
                            <div className="c-hero__visual-blob c-hero__visual-blob-2">
                            </div>
                            <img className="c-hero__visual-img" src={LucHeroImage} alt="Luc Haeck"/>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="u-container c-dark-overview">
                    <h1>
                        Onze diensten
                    </h1>
                    <div className="row gap-5">
                        <div className="col c-card">
                            <div className="c-card__header">
                                <div className="c-card__header-circle">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24.8" height="27.333" viewBox="0 0 24.8 27.333">
                                        <g id="Icon_feather-home" data-name="Icon feather-home" transform="translate(-3.5 -2)">
                                            <path id="Path_5" data-name="Path 5" d="M13.5,27.5V18h5.7v9.5" transform="translate(-0.45 0.833)" fill="none" stroke="#fdfdfd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                                            <path id="Path_4" data-name="Path 4" d="M4.5,11.867,15.9,3l11.4,8.867V25.8a2.533,2.533,0,0,1-2.533,2.533H7.033A2.533,2.533,0,0,1,4.5,25.8Z" fill="none" stroke="#fdfdfd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                                        </g>
                                    </svg>
                                </div>
                                <h2>
                                    EPC
                                </h2>
                            </div>
                            <p>
                                Onze energiedeskundigen helpen u met veel plezier. Een EPC is cruciaal voor de verkoop of verhuur van uw woning.
                            </p>
                            <a href="/epc">
                                Ontdek meer
                            </a>
                        </div>
                        <div className="col c-card">
                            <div className="c-card__header">
                                <div className="c-card__header-circle">
                                <svg xmlns="http://www.w3.org/2000/svg" width="32.465" height="28.325" viewBox="0 0 32.465 28.325">
                                    <g id="Group_43" data-name="Group 43" transform="translate(-1.29 -12.778)">
                                        <path id="Path_7" data-name="Path 7" d="M18,13.5v8.83" transform="translate(-0.478 7.409)" fill="none" stroke="#fdfdfd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                                        <path id="Path_8" data-name="Path 8" d="M18,25.5h0" transform="translate(-0.478 9.575)" fill="none" stroke="#fdfdfd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                                        <path id="Path_6" data-name="Path 6" d="M15.035,5.746,2.718,26.309A2.908,2.908,0,0,0,5.2,30.671H29.839a2.908,2.908,0,0,0,2.487-4.363L20.008,5.746a2.908,2.908,0,0,0-4.973,0Z" transform="translate(0 9.432)" fill="none" stroke="#fdfdfd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                                    </g>
                                </svg>
                                </div>
                                <h2>
                                    Asbest attest
                                </h2>
                            </div>
                            <p>
                            Een asbest attest nodig of wilt u meer informatie over asbest? The House Coach biedt ook hier een oplossing voor u.
                            </p>
                            <a href="/asbest-attest">
                                Ontdek meer
                            </a>
                        </div>
                        <div className="col c-card-g">
                            <div className="c-card__header">
                                <h2>
                                    Niet gevonden wat u zocht?
                                </h2>
                            </div>
                            <p>
                                Contacteer ons gerust, dan zoeken we samen naar een oplossing
                            </p>
                            <a href="/contact">
                                Contacteer ons
                            </a>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <section>
                <div className="u-container">
                    <div className="c-home-cta">
                        <div className="c-home-cta__content">
                            <h2>
                                The House Coach biedt met jaren ervaring waardevolle oplossingen, voor ú!
                            </h2>
                            <Button
                                text="Contacteer ons"
                                link="/contact"
                            />
                        </div>
                        <div className='c-home-cta__visual'>
                            
                        </div>
                    </div>
                </div>
            </section> */}
            <section className="u-marg-y" id="discover">
                <div className="u-container">
                    <div className="c-home-services">
                        <h2>
                            Onze diensten om uw woning in kaart te brengen.
                        </h2>
                        <div className='c-home-services__carousel'>
                            <div className="c-home-services__carousel-card">
                                <img src={AsbestosImage} alt="Asbest Attest"/>
                                <div className="c-home-services__carousel-card_note">
                                    <p>
                                        Asbest attest
                                    </p>
                                    <a href="/asbest-attest">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 24 24" role="img" aria-labelledby="asbest-cta-arrow" stroke="#000000" strokeWidth="1" strokeLinecap="square" strokeLinejoin="miter" fill="none" color="#000000"> <title id="asbest-cta-arrow">Asbest attest</title> <path d="M19 13V5h-8"/> <path strokeLinecap="round" d="M19 5l-1 1"/> <path d="M18 6L5 19"/> </svg>
                                    </a>
                                </div>
                            </div>
                            <div className="c-home-services__carousel-card">
                                <img src={EPCImage} alt="EPC"/>
                                <div className="c-home-services__carousel-card_note">
                                    <p>
                                        EPC
                                    </p>
                                    <a href="/epc">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 24 24" role="img" aria-labelledby="epc-cta-arrow" stroke="#000000" strokeWidth="1" strokeLinecap="square" strokeLinejoin="miter" fill="none" color="#000000"> <title id="epc-cta-arrow">EPC</title> <path d="M19 13V5h-8"/> <path strokeLinecap="round" d="M19 5l-1 1"/> <path d="M18 6L5 19"/> </svg>
                                    </a>
                                </div>
                            </div>
                            <div className="c-home-services__carousel-card">
                                <img src={ConstructImage} alt="Construct huizen"/>
                                <div className="c-home-services__carousel-card_note">
                                    <p>
                                        Niet gevonden wat u zocht?
                                    </p>
                                    <a href="/contact">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 24 24" role="img" aria-labelledby="contact-cta-arrow" stroke="#000000" strokeWidth="1" strokeLinecap="square" strokeLinejoin="miter" fill="none" color="#000000"> <title id="contact-cta-arrow">Contacteer ons</title> <path d="M19 13V5h-8"/> <path strokeLinecap="round" d="M19 5l-1 1"/> <path d="M18 6L5 19"/> </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ContactCTA />
        </>
    );
}

export default Home