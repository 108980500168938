import { useState } from 'react';
import InputField from '../Inputs/InputField';
import './ContactCTA.css';

const ContactCTA = () => {
    const [responseMessage, setResponseMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);

        try {
            const response = await fetch('https://thehousecoach.be/send_mail.php', {
                method: 'POST',
                body: formData
            });

            const data = await response.json();
            setResponseMessage(data.message);

            if (response.ok) {
                form.reset(); // Reset the form
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    return(
        <section className="c-contact-cta">
            <div className="u-container">
                <div className="c-contact-cta__row">
                    <div>
                        <h2>
                            Contacteer ons
                        </h2>
                        <p>
                            Neem contact met ons op indien u nood heeft aan een van onze diensten of als u vragen heeft.
                        </p>
                        <div className="c-contact-cta__detail">
                            <a href="tel:+32475732740"> 
                                <div className='c-contact-cta__icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                        <path id="Icon_awesome-phone-alt" data-name="Icon awesome-phone-alt" d="M15.543,11.306l-3.5-1.5a.75.75,0,0,0-.875.216l-1.55,1.894A11.583,11.583,0,0,1,4.081,6.378l1.894-1.55a.748.748,0,0,0,.216-.875l-1.5-3.5A.755.755,0,0,0,3.831.019L.581.769A.75.75,0,0,0,0,1.5,14.5,14.5,0,0,0,14.5,16a.75.75,0,0,0,.731-.581l.75-3.25a.759.759,0,0,0-.438-.862Z" fill="#fdfdfd"/>
                                    </svg>
                                </div>
                                +32 475 73 27 40
                            </a>
                        </div>
                        <div className="c-contact-cta__detail">
                            <a href="mailto:luc@thehousecoach.be"> 
                                <div className='c-contact-cta__icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 16">
                                        <path id="Icon_material-email" data-name="Icon material-email" d="M19.333,2.667h-16a2,2,0,0,0-1.99,2l-.01,12a2.006,2.006,0,0,0,2,2h16a2.006,2.006,0,0,0,2-2v-12a2.006,2.006,0,0,0-2-2Zm0,4-8,5-8-5v-2l8,5,8-5v2Z" transform="translate(-1.333 -2.667)" fill="#fdfdfd"/>
                                    </svg>
                                </div>
                                luc@thehousecoach.be
                            </a>
                        </div>
                    </div>
                    <div>
                        <form className="c-contact-cta__form" onSubmit={handleSubmit}>
                                <InputField 
                                name={"email"}
                                labelText={"E-mail"}
                                type={"email"}
                                classes={"col-12 col-md-6"}
                                />
                                <InputField 
                                name={"first_name"}
                                labelText={"Voornaam"}
                                type={"text"}
                                classes={"col-12 col-md-4"}
                                />
                                <InputField 
                                name={"message"}
                                labelText={"Bericht"}
                                isTextArea={true}
                                classes={"col-12"}
                                />
                                <div className="c-contact-cta-fb">
                                    <button type='submit' className='c-contact-cta-btn'>
                                        Verzenden
                                    </button>
                                    {responseMessage && <span className='c-contact-cta__success'>{responseMessage}</span>}
                                </div>                                
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactCTA;
