import React, { useEffect } from 'react';

const SetMetaData = (Component, title, meta_description, meta_keywords) => {
  const default_keywords = "Vastgoedanalyse, Gebouwinspectie, Vastgoedadvies, Energieprestatie, Asbestonderzoek, Vastgoedexpertise, Vastgoedbeoordeling, Woninginspectie, Vastgoedconsultatie, Vastgoedmarkt Oost-Vlaanderen, Vastgoedbeheer Gent, Vastgoedtransacties Vlaanderen, Energie-efficiëntieadvies, Bouwtechnische inspectie, Vastgoedmanagement, Duurzaam vastgoedadvies, Vastgoedwaardering, Huisanalyse België, Vastgoedtaxatie Vlaanderen, Vastgoedontwikkeling Gent, ";

  const WrappedComponent = props => {
    useEffect(() => {
      document.title = `The House Coach${title}`;

      const metaDescTag = document.querySelector('meta[name="description"]');
      if (metaDescTag) {
        metaDescTag.setAttribute('content', meta_description);
      } else {
        const newMetaDescTag = document.createElement('meta');
        newMetaDescTag.setAttribute('name', 'description');
        newMetaDescTag.setAttribute('content', meta_description);
        document.head.appendChild(newMetaDescTag);
      }

      const metaKeywordsTag = document.querySelector('meta[name="keywords"]');
      if (metaKeywordsTag) {
        metaKeywordsTag.setAttribute('content', default_keywords + meta_keywords);
      } else {
        const newMetaKeywordsTag = document.createElement('meta');
        newMetaKeywordsTag.setAttribute('name', 'keywords');
        newMetaKeywordsTag.setAttribute('content', default_keywords + meta_keywords);
        document.head.appendChild(newMetaKeywordsTag);
      }
    }, []);

    return <Component {...props} />;
  };

  return WrappedComponent;
};

export default SetMetaData;
